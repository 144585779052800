<template>
    <div v-if="loading" class="pageLoader"></div>
</template>

<script>
export default {
    name: 'PageLoader',
    props: {
        loading: {
            type: Boolean,
            default: () => {}
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';

.pageLoader {
    position: fixed;
    top: 46px;
    left: 0;
    width: 200px;
    height: 4px;
    background: #ffffffbf;
    animation: load 1s infinite;
}

@keyframes load {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}

</style>
