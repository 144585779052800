export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon Footprint Tracking"])},
  "Production Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lot Tracking"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "Are you sure you want to log out?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to lot out?"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Log out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "Sign Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "Welcome!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
}