export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi de L'empreinte Carbone"])},
  "Production Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi des Lots"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "Are you sure you want to log out?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir vous déconnecter?"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
  "Log out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
  "Sign Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
  "Welcome!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue!"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])}
}