<template>
  <div class="w-full h-full">
    <Toast />
    <PageLoader :loading="loading" />
    <iframe class="guest-frame" id="guest" :src="target_host + target_path" />
  </div>
</template>

<script>
  import PageLoader from '@/components/PageLoader/PageLoader';

  export default {
    components: {
      PageLoader,
    },
    created() {
      window.addEventListener('message', this.processMessage);
    },
    unmounted() {
      window.removeEventListener('message', this.processMessage);
    },
    mounted() {},
    data() {
      return {
        target_host: process.env.VUE_APP_MAIN_FEATURESET_ORIGIN,
        target_path: process.env.VUE_APP_MAIN_FEATURESET_PATHPREFIX + '/dashboard',
        loading: true,
      };
    },
    methods: {
      processMessage(message) {
        if (message.data.type === 'guestIsReady') {
          document
            .getElementById('guest')
            .contentWindow.postMessage([{ code: 'idTokenChange', value: this.$auth.state.idToken }, { code: 'selectedLanguageChanged', value: localStorage.getItem("app_locale") }], this.target_host);
          this.loading = false;
        }
        if (message.data.type === 'tokenExpired') {
          this.$auth.bus.emit('logout');
          this.loading = true;
        }
      },
    },
    computed: {},
  };
</script>

<style lang="scss" scoped>
  @import '../assets/mixins/breakpoints.scss';
</style>
