<template>
  <div class="app-main">
    <confirm-dialog ref="confirmDialog"/>
    <AuthGoogle>
      <div class="app-header">
        <img alt="Traceability Platform" class="left-logo ml-3 clickable" :src="logoOTP" @click="goToDashboard()" />
        <div class="main-search-box flex-grow-1">
          <div class="p-input-icon-left main-search-container">
            <i class="pi pi-search" />
            <InputText type="text" v-model="searchString" :placeholder="$t('Search')" @input="searchEvent" />
            <Dialog
              v-model:visible="searchVisible"
              position="top"
              :showHeader="false"
              :style="{ width: '100%', 'max-width': '900px', 'max-height': '80vh', 'margin-top': '50px' }"
            >
              <div v-if="!searchString">Please enter a search term in the field above to search for something</div>
              <div v-else-if="searching">Searching...</div>
              <div v-else-if="searchResults">
                <div v-if="searchLots.length">
                  <div class="search-result-section">Top Lot # Matches</div>
                  <DataTable :value="searchLots">
                    <Column field="lot" header="Lot #">
                      <template #body="{ data }">
                        {{ data.lot }}
                      </template>
                    </Column>
                    <Column field="details.product.id" header="Material">
                      <template #body="{ data }">
                        {{ idToString(data.details.product.id) }}
                      </template>
                    </Column>
                    <Column field="details.product.name" header="Product" />
                    <Column field="used" header="Used In">
                      <template #body="{ data }">
                        <div v-if="data.used.length">
                          <div v-for="(l, i) of data.used" class="clickable" :key="i" @click="goToLot(l, data.lot)">
                            {{ l }}
                          </div>
                        </div>
                        <div v-else>N/A</div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="searchMaterials.length">
                  <div class="search-result-section">Top Material Matches</div>
                  <DataTable :value="searchMaterials">
                    <Column field="material" header="Material" />

                    <Column header="Variants">
                      <template #body="{ data }">
                        <div v-for="(m, i) of data.material_details.names" :key="i">
                          {{ m }}
                        </div>
                      </template>
                    </Column>
                    <Column field="supplier.name" header="Supplier">
                      <template #body="{ data }">
                        <div class="clickable" @click="goToCompany(data.supplier.id)">
                          {{ data.supplier.name }}
                        </div>
                      </template>
                    </Column>
                    <Column header="Consumers">
                      <template #body="{ data }">
                        <div v-for="(c, i) of data.buyers" :key="i" class="clickable" @click="goToCompany(c.id)">
                          {{ c.name }}
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="searchSuppliers.length">
                  <div class="search-result-section">Top Supplier Matches</div>
                  <DataTable :value="searchSuppliers">
                    <Column field="name" header="Company">
                      <template #body="{ data }">
                        <div class="clickable" @click="goToCompany(data.id)">
                          {{ data.name }}
                        </div>
                      </template>
                    </Column>
                    <Column field="type" header="Production Type">
                      <template #body="{ data }">
                        {{ data.type }}
                      </template>
                    </Column>
                    <Column field="type" header="Supplier Type">
                      <template #body="{ data }">
                        <span v-for="(c, i) of data.types" :class="'mr-2 tag ' + c.style" :key="i">{{ c.text }}</span>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="searchTickets.length">
                  <div class="search-result-section">Top Ticket Matches</div>
                  <DataTable :value="searchTickets">
                    <Column field="name" header="ID">
                      <template #body="{ data }">
                        <div class="clickable" @click="goToTicket(data.hid)">
                          {{ data.hid }}
                        </div>
                      </template>
                    </Column>
                    <Column field="type" header="Type">
                      <template #body="{ data }">
                        {{ data.type }}
                      </template>
                    </Column>
                    <Column field="type" header="Created">
                      <template #body="{ data }">
                        {{ new Date(data.created).toLocaleDateString() }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
              <div v-else>Nothing found for {{ searchString }}</div>
            </Dialog>
          </div>
        </div>
        <div class="top-menu mr-4">
          <Button class="" @click="toggleLanguage" label="Select Language" aria-haspopup="true" aria-controls="overlay_menu">
              <div class="flex flex-row align-items-center justify-content-center">
                <img src="assets/en.svg" width="50" height="20" alt="" class="mr-1">
                <p>{{$t('EN')}}</p>
              </div>
              <Menu class="p-menu pr-1 w-1" ref="lang" :model="countries" :popup="true" />
          </Button>
        </div>
        <div class="top-menu mr-5">
          <Button class="p-button" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
              <img v-if="$auth.state.idTokenParsed.picture" class="pi pi-user user-button" :src="$auth.state.idTokenParsed.picture" />
              <i v-else class="pi pi-user user-button" />
              <i class="pi pi-chevron-down" style="font-size: 0.8rem; margin-left: 1px;"/>
              <Menu class="profile-menu" ref="menu" :model="items" :popup="true" />
          </Button>
        </div>
      </div>
      <div class="app-body">
        <div v-if="openSidebar" class="app-side-bar">
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center justify-content-around w-full my-3">
              <div class="interactable mt-3" @click="goToDashboard()">
                <img :src="require(`@/assets/images/glencore-white-logo.png`)" height="50" />
              </div>
              <img class="clickable mt-3 mb-2" :src="require(`@/assets/icons/menu-handle.svg`)" @click="toggleSidebar()" />
            </div>
          </div>
          <div class="side-menu">
            <PanelMenu
              :model="appMenu"
              v-model:expandedKeys="expandedKeys"
              :openSidebar="openSidebar"
              :activeMenuItem="activeMenuItem"
            />
          </div>
        </div>
        <div v-else class="app-side-bar small">
          <div class="small-sidebar-header">
            <img class="clickable" :src="require(`@/assets/icons/menu-handle.svg`)" @click="toggleSidebar()" />
            <img class="optchain-icon" :src="require(`@/assets/icons/optchain-bar-small.svg`)" />
          </div>
          <div class="side-menu small">
            <PanelMenu
              :model="appMenu"
              v-model:expandedKeys="expandedKeys"
              :openSidebar="openSidebar"
              @click="toggleSidebar"
              :activeMenuItem="activeMenuItem"
            />
          </div>
        </div>
        <div :class="(viewWidth > 1100 ? '' : 'small ') + 'app-content'">
          <ModalIdle v-if="isIdle" />
          <router-view />
        </div>
      </div>
    </AuthGoogle>
  </div>
</template>

<script>
  import AuthGoogle from '@/components/auth/AuthGoogle';
  import PanelMenu from '@/components/panelmenu/PanelMenu';
  import ModalIdle from '@/components/ModalIdle/ModalIdle';
  import { fakeSearchLot, fakeSearchMaterials, fakeSearchSuppliers, decodeLot, idToString } from './services/interface';
  import { getFromRpc } from './services/api';
  import ConfirmDialog from './components/ConfirmDialog.vue';
 


  export default {
    name: 'App',
    components: {
      AuthGoogle,
      PanelMenu,
      ModalIdle,
      ConfirmDialog
    },
    data() {
      return {
        viewWidth: window.visualViewport.width,
        expandedKeys: {},
        category: null,
        isLoggedIn: false,
        tstate: null,
        searching: false,
        searchString: '',
        searchResults: 0,
        searchVisible: false,
        searchDebounce: null,
        searchMaterials: [],
        searchLots: [],
        searchSuppliers: [],
        searchTickets: [],
        openSidebar: true,
        activeMenuItem: {},
        selectedLanguage: null,
        userMenu: [
          {
            label: this.$t('Logout'),
            icon: 'pi pi-sign-out',
            command: this.logOut,
          },
        ],
        appMenu: [
          {
            label: this.$t('Dashboard'),
            icon: 'pi pi-th-large',
            to: '/dashboard',
            visible: this.canUserAccessRoute(['admin', 'tech', 'user', 'guest']),
          },
          {
            label: this.$t('Emissions Factors'),
            icon: 'fas fa-industry',
            to: '/EmissionsFactors',
            visible: this.canUserAccessRoute(['admin', 'tech', 'user', 'guest']),
          },
          {
            label: this.$t('Production Map'),
            icon: 'pi pi-chart-bar',
            to: '/production_map',
            visible: this.canUserAccessRoute(['admin', 'tech', 'user', 'guest']),
          },
          {
            label: this.$t('Settings'),
            icon: 'fas fa-cog',
            to: '/settings',
            visible: this.canUserAccessRoute(['admin', 'tech']),
          },
        ],
        items: [
				{
					label: this.$t('Profile'),
					icon: 'pi pi-user-edit',
					command: () => {
						this.$router.push('settings')
					}
				},
				{
					label: this.$t('Sign Out'),
					icon: 'pi pi-sign-out',
					command: () => {
						this.$refs.confirmDialog.show();
					}
                }
			],
      countries: [
        {
          label: this.$t('English'),
          command: () => {
            this.selectedLanguage = 'en';
            localStorage.setItem("app_locale", this.selectedLanguage);
            window.location.reload();
					}
        },
        {
          label: this.$t('French'),
          command: () => {
            this.selectedLanguage = 'fr';
            localStorage.setItem("app_locale", this.selectedLanguage);
            window.location.reload();
					}
        },
    ],
      };
    },

    created() {
      window.addEventListener('resize', this.processWidth);
      this.$bus.on('set-category', this.setCategory);
    },
    unmounted() {
      this.$bus.off('set-category', this.setCategory);
      window.removeEventListener('message', this.processMessage);

    },
    mounted() {
      this.processCategory();
    },
    methods: {
      toggle(event) {
        this.$refs.menu.toggle(event);
      },
      toggleLanguage(event) {
        this.$refs.lang.toggle(event);
      },
      processCategory() {
        this.category = this.$router.options.routes.find((r) => r.path === this.$router.currentRoute.value.path)?.cat;
        this.setCategory(this.category);
      },
      setCategory(cat) {
        Object.keys(this.expandedKeys).forEach((k) => (this.expandedKeys[k] = false));
        Object.keys(this.activeMenuItem).forEach((k) => (this.activeMenuItem[k] = false));
        cat?.forEach((c) => {
          this.expandedKeys[c] = true;
          this.activeMenuItem[c] = true;
        });
        this.openSidebar = true;
        if (cat?.includes('landing')) {
          if (
            !this.$router.options.routes
              .filter((r) => r.cat?.sort().join() === cat?.sort().join())
              .map((r) => r.path)
              .includes(this.$router.currentRoute.value.path)
          ) {
            this.$router.push({ path: '/landing2' });
          }
        } else if (cat?.includes('inventory')) {
          if (
            !this.$router.options.routes
              .filter((r) => r.cat?.sort().join() === cat?.sort().join())
              .map((r) => r.path)
              .includes(this.$router.currentRoute.value.path)
          ) {
            this.$router.push({ path: '/inventory' });
          }
        }
      },
      idToString(id) {
        return idToString(id);
      },
      hideSearch() {
        this.searchVisible = false;
      },
      searchEvent() {
        this.searching = true;
        this.searchResults = 0;
        if (!this.searchVisible) {
          this.searchVisible = true;
        }
        if (this.searchDebounce) {
          clearTimeout(this.searchDebounce);
        }
        this.searchDebounce = setTimeout(this.doSearch, 400);
      },
      doSearch() {
        this.searchDebounce = null;
        this.searchResults = 0;
        const slp = new Promise((resolve) => {
          fakeSearchLot(this.searchString).then((sl) => {
            this.searchLots = sl;
            this.searchResults += sl.length;
            resolve();
          });
        });
        const smp = new Promise((resolve) => {
          fakeSearchMaterials(this.searchString).then((sm) => {
            this.searchMaterials = sm;
            this.searchResults += sm.length;
            resolve();
          });
        });
        const ssp = new Promise((resolve) => {
          fakeSearchSuppliers(this.searchString).then((ss) => {
            this.searchSuppliers = ss;
            this.searchResults += ss.length;
            resolve();
          });
        });
        const stp = new Promise((resolve) => {
          getFromRpc('searchTickets', [this.searchString]).then((st) => {
            this.searchTickets = st;
            this.searchResults += st.length;
            resolve();
          });
        });
        Promise.all([slp, smp, ssp, stp]).then(() => {
          this.searching = false;
        });
      },
      goToLot(lot, material_lot) {
        decodeLot(material_lot).then((ml) => {
          decodeLot(lot).then((l) => {
            this.$router.push({ path: '/production', query: { dates: [ml.date, l.date], lot, material_lot } });
            this.searchVisible = false;
          });
        });
      },
      goToCompany(company) {
        this.$router.push({ path: '/supply', query: { company } });
        this.searchVisible = false;
      },
      goToTicket(ticket) {
        this.$router.push({ path: '/tickets', query: { ticket } });
        this.searchVisible = false;
      },
      goToDashboard() {
        this.$router.push({ path: '/dashboard' });
      },
      goToLanding() {
        this.$router.push({ path: '/landing' });
      },
      logOut() {
        this.$auth.bus.emit('logout');
      },
      processWidth() {
        this.viewWidth = window.visualViewport.width;
      },
      toggleSidebar() {
        Object.keys(this.expandedKeys).forEach((k) => (this.expandedKeys[k] = false));
        this.openSidebar = !this.openSidebar;
      },
      getUserRole() {
        try {
          return this.$auth.state.idTokenParsed.roles ? this.$auth.state.idTokenParsed.roles[0] : null;
        } catch (err) {
          console.log(err);
          this.logOut();
          return 'error';
        }
      },
      canUserAccessRoute(roleRequired) {
        return () => {
          return roleRequired.some((role) => role === this.getUserRole());
        };
      },
    },
    computed: {
      logoOptel() {
        return 'assets/OptelLogo.svg';
      },
      logoOptelWh() {
        return 'assets/MonochromeOptelLogo.svg';
      },
      logoOTP() {
        return 'assets/PortalLogo.svg';
      },
      partnerLogo() {
        return process.env.VUE_APP_PARTNER_LOGO;
      },
      isIdle() {
        return this.$store.state.idleVue.isIdle;
      },
    },
    watch: {
      $route() {
        this.processCategory();
      },
    },
  };
</script>
<style lang="scss">
  .fade-in {
    animation: fadeIn linear 0.3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-out {
    animation: fadeOut linear 0.3s;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>

<style lang="scss" scoped>
  @import './assets/_variables.scss';

  .pi-flag-fill
{
    background-color: #003C71;
  }
  .app-main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .app-header {
    background-color: #003c71;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12)
    border-bottom: 4px solid $optchainMediumBlue;
  }
  .app-side-bar {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 320px;
    min-width: 200px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #003c71;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15) !important;

    &.small {
      align-items: center;
      max-width: 80px;
      width: 80px;
      min-width: 80px;
    }
  }
  .app-body {
    display: flex;
    height: calc(100vh - 50px);
    max-width: 100vw;
    width: 100vw;
  }
  .app-content {
    padding: 0;
    // max-width: calc(100% - 200px);
    width: calc(100vw - 200px);
    flex: 1 2 auto;
    height: calc(100vh - 50px);
    overflow: none;

    &.small {
      min-width: calc(100% - 50px);
      max-width: calc(100% - 50px);
      width: calc(100vw - 50px);
    }
  }
  .search-result-section {
    font-size: 120%;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .side-menu {
    // padding-top: 20px;
    // max-height: calc(100vh - 50px - 85px);
    // overflow: hidden;
    padding: 0 10px;
    overflow: auto;
    &.small {
      width: 100%;
      padding: 0;
      overflow: visible;
    }
  }

  .side-menu::-webkit-scrollbar {
    display: none;
  }

  .left-logo {
    height: 30px;
  }
  .side-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .optel-logo {
    width: 150px;
  }

  .bv-logo {
    width: 100px;
  }
  .logo {
    margin: 5px;
  }
  .top-menu {
    display: flex;
    align-items: center;
  }
  .username {
    color: #fff;
    font-weight: 600;
  }
  .user-button {
    color: #003c71;
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 25px;
    // border: 12px solid #003C71;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  .divider {
    height: 35px;
    width: 2px;
    background-color: #fff;
    margin: 5px 20px;
  }
  .clickable {
    cursor: pointer;
  }
  .main-search-container {
    width: 100%;
    input {
      height: 34px;
    }
  }
  .main-search-box {
    padding: 0px 50px;
    max-width: 600px;
  }

  .search-options {
    width: 100%;
    height: 600px;
    position: absolute;
  }

  :deep(.p-submenu-list) {
    // border-bottom: 1px solid $primaryColor;
    background: $primaryColor;
    // margin-bottom: 1em;
  }

  :deep(.p-menuitem-link) {
    color: #ffffff !important;
    background: $primaryColor;
    display: flex;
    justify-content: space-between;
    margin: 0 0 2px 1.5rem;
    border-radius: 12px !important;
    &:not(.p-disabled):hover {
      // background: $primaryColor;
    }
    &:focus {
      box-shadow: none !important;
    }
  }

  :deep(.router-link-active-exact .p-menuitem-text) {
    font-weight: 500;
  }

  :deep(.p-input-icon-left > .p-inputtext) {
    width: 100%;
  }

  :deep(.p-menuitem-text) {
    color: #fff !important;
  }

  .small-sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    .optchain-icon {
      width: 60px;
      margin-top: 40px;
    }
}
.p-button {
    background: #003C71;
    font-size: 1rem;
     border: 1px solid transparent;
    transition: background-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    padding: 2px 5px;
}
</style>
