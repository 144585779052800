import axios from 'axios';

let token = null;

export const setToken = (t) => token = t;


export const getFromRpc = (fn, args) => {
    return axios.get(`${process.env.VUE_APP_API_URL}/call`, {
        params : {fn, args: JSON.stringify(args)},
        headers: {'Authorization': `Bearer ${token}`}
    }).then(r => r.data);
}

export const getFromEp = (ep, params, token) => {
    return axios.get(`${process.env.VUE_APP_API_URL}/${ep}`, {
        params,
        headers: {'Authorization': `Bearer ${token}`}
    })
}