<template>
  <Dialog
    header="Are you still there?"
    :modal="true"
    v-model:visible="display"
  >
    <div> 
      <p>You have been idle for 10 minutes.</p>
      <p>For your safety, your session will expire in {{ timeUntilExpiration }} second{{ timeUntilExpiration !== 1 ? 's' : '' }}.</p>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "ModalIdle",
  data() {
    return {
      display: this.$store.state.idleVue.isIdle,
      time: 30 * 1000,
      timerId: null,
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timerId = setInterval(() => {
        this.time -= 1000;
        if (!this.$store.state.idleVue.isIdle) clearInterval(this.timerId);
        if (this.time < 1) {
          clearInterval(this.timerId);
          this.$store.state.idleVue.isIdle = false;
          this.$auth.bus.emit('logout');
        }
      }, 1000);
    }
  },
  watch: {
    '$store.state.idleVue.isIdle': function(newValue) {
      if (!newValue) {
        clearInterval(this.timerId);
        this.time = 30 * 1000;
      }
    }
  },
  computed: {
    timeUntilExpiration() {
      return this.time / 1000;
    }
  }
};
</script>


<style lang="scss" scoped>

</style>