import { createRouter, createWebHashHistory } from 'vue-router'
import Settings from '../views/Settings.vue';
import Dashboard from '../views/Dashboard.vue';
import ProductionMap from '../views/ProductionMap.vue';
import EmissionsFactors from '../views/EmissionsFactors.vue';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/EmissionsFactors',
    name: 'EmissionsFactors',
    component: EmissionsFactors,
  },
  {
    path: '/production_map',
    name: 'Production Map',
    component: ProductionMap,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  { path: '/', redirect: '/dashboard' },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
