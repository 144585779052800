<template>
  <div>
    <Toast />
    <div v-show="!isLoggedIn" class="grid full-screen">
      <div class="left-side">
        <img class="" :src="require(`@/assets/images/login-bg.png`)" />
        <img class="reinventing" :src="require(`@/assets/images/tagline.svg`)" />
      </div>
      <div class="right-side">
        <div class="top-right">
          <div class="logo-box">
            <img alt="optchain" class="logo" :src="logoOptel" />
          </div>
        </div>
        <div class="bottom-right">
          <div class="greet">{{$t('Welcome!')}}</div>
          <div v-if="providers.includes('email')">
            <span class="login-field"
              ><i class="pi pi-user mr-2" /><InputText
                v-model="email"
                v-on:keyup.enter="startLogin({ type: 'email' })"
                style="width: 100%"
            /></span>
            <span class="login-field"
              ><i class="pi pi-lock mr-2" /><Password
                v-model="password"
                :feedback="false"
                v-on:keyup.enter="startLogin({ type: 'email' })"
                :toggleMask="true"
                style="width: 100%"
                inputStyle="width: 100%"
            /></span>
            <div class="flex justify-content-center">
              <Button
                :label="$t('Login')"
                icon="fas fa-user-circle"
                class="login-button"
                @click="startLogin({ type: 'email' })"
              />
            </div>
          </div>
          <div v-if="Object.keys(loginOptions).length">
            <!-- <div>You may sign-in using one of the SSO providers bellow :</div> -->
            <Button
              v-for="(o, i) of loginOptions"
              :label="$t(o.name)"
              :icon="o.icon"
              class="login-button"
              @click="startLogin(o)"
              :key="i"
            />
            <div id="recaptcha-container" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoggedIn">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import { setToken } from '../../services/api';

  firebase.initializeApp({
    apiKey: process.env.VUE_APP_GIDP_APIKEY,
    authDomain: process.env.VUE_APP_GIDP_AUTHDOMAIN,
  });

  export default {
    name: 'AuthGoogle',
    data() {
      return {
        providers: process.env.VUE_APP_GIDP_PROVIDERS.split(',').map((p) => p.trim()),
        email: null,
        password: null,
        loginOptions: {},
        isLoggedIn: false,
      };
    },
    created() {
      const tstate = localStorage.getItem('auth_state');
      if (tstate) {
        this.$auth.state = JSON.parse(tstate);
      } else {
        this.$auth.state = {};
      }
      this.setupProviders();

      firebase.auth().tenantId = process.env.VUE_APP_GIDP_TENANT;

      firebase.auth().onAuthStateChanged(this.login);

      this.$auth.bus.on('logout', this.logout);
      this.$auth.bus.on('refreshToken', this.refreshToken);
    },
    mounted() {},
    methods: {
      async login(user) {        
        if (user) {
          this.$auth.state = {
            idToken: await user.getIdToken(),
          };
          this.$auth.state.isLoggedIn = this.isTokenValid();
          localStorage.setItem('auth_state', JSON.stringify(this.$auth.state));
          this.$auth.state.idTokenParsed.roles = this.$auth.state.idTokenParsed.roles ? this.$auth.state.idTokenParsed.roles : ['admin'];
          setToken(this.$auth.state.idToken);
          this.isLoggedIn = this.$auth.state.isLoggedIn;
          this.$router.push(this.$auth.state.idTokenParsed.route);
        }
      },
      refreshToken() {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            this.$auth.state = {
              idToken: token,
            };
            this.$auth.state.isLoggedIn = this.isTokenValid();
            localStorage.setItem('auth_state', JSON.stringify(this.$auth.state));
            setToken(this.$auth.state.idToken);
            this.isLoggedIn = this.$auth.state.isLoggedIn;
            this.$auth.bus.emit('idTokenChanged', token);
          }).catch(() => {
            this.$auth.bus.emit('logout');
          })
      },
      startLogin(o) {
        if (o.type === 'email') {
          if (this.email && this.password) {
            firebase
              .auth()
              .signInWithEmailAndPassword(this.email, this.password)
              .catch(() => {
                this.$toast.add({
                  severity: 'warn',
                  summary: 'Authentication Error',
                  detail: 'The login information provided is incorrect.',
                  life: 9000,
                });
              });
          }
        } else {
          firebase.auth().signInWithPopup(o.provider);
        }
      },
      directLogin() {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .catch((e) => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Logging In',
              detail:
                e.code === 'auth/wrong-password'
                  ? 'Incorrect credentials or unknown user. Please check and try again.'
                  : e.message,
              life: 9000,
            });
          });
      },
      providerLogin(p) {
        firebase.auth().signInWithPopup(p);
      },
      logout() {
        firebase.auth().signOut();
        this.$auth.state.idToken = null;
        this.$auth.state.idTokenParsed = null;
        this.$auth.state.refreshToken = null;
        this.$auth.state.isLoggedIn = false;
        localStorage.setItem('auth_state', JSON.stringify(this.$auth.state));
        this.isLoggedIn = false;
      },
      isTokenValid() {
        // TODO : Validate the token signature...
        this.$auth.state.idTokenParsed = JSON.parse(
          Buffer.from(this.$auth.state.idToken.split('.')[1], 'base64').toString()
        );

        if (this.$auth.state.idTokenParsed) {
          if (
            this.$auth.state.idTokenParsed.exp * 1000 < Date.now() ||
            this.$auth.state.idTokenParsed.iat * 1000 > Date.now() + 5000 ||
            this.$auth.state.idTokenParsed.aud !== process.env.VUE_APP_AUTH_GCP_AUD ||
            this.$auth.state.idTokenParsed.iss !== process.env.VUE_APP_AUTH_GCP_ISS ||
            !this.$auth.state.idTokenParsed.sub
          ) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Authentication Error',
              detail: 'The login information returned by your provider is incorrect. Please try again later.',
              life: 9000,
            });
            this.logout();
            return false;
          }

          if (
            this.$auth.state.idTokenParsed.firebase.sign_in_provider === 'google.com' &&
            !process.env.VUE_APP_GOOGLE_DOMAINS.split(',')
              .map((d) => d.trim())
              .includes(this.$auth.state.idTokenParsed.email.split('@')[1])
          ) {
            this.$toast.add({
              severity: 'warn',
              summary: 'No Authorized',
              detail: 'You have successfully signed in, but your account is not authorized to access this platform.',
              life: 9000,
            });
            this.logout();
            return false;
          }
          return true;
        }
        this.$toast.add({
          severity: 'warn',
          summary: 'Error',
          detail: 'No login information was returned by your provider. Please try again later.',
          life: 9000,
        });
        return false;
      },
      setupProviders() {
        this.providers.forEach((p) => {
          let provider = null;
          if (p === 'email') {
            provider = {
              provider: new firebase.auth.EmailAuthProvider(),
              type: p,
              name: 'Local Account',
              icon: 'fas fa-user-circle',
            };
          } else if (p === 'facebook') {
            provider = {
              provider: new firebase.auth.FacebookAuthProvider(),
              type: p,
              name: 'Facebook',
              icon: 'fab fa-facebook',
            };
          } else if (p === 'github') {
            provider = {
              provider: new firebase.auth.GithubAuthProvider(),
              type: p,
              name: 'GitHub',
              icon: 'fab fa-github',
            };
          } else if (p === 'google') {
            provider = {
              provider: new firebase.auth.GoogleAuthProvider(),
              type: p,
              name: 'Google',
              icon: 'fab fa-google',
            };
          }

          if (provider && p !== 'email') {
            this.loginOptions[p] = provider;
          }
        });
      },
    },
    computed: {
      logoOptel() {
        return 'assets/PortalLogo-Blue.svg';
      },
      logoOptelWh() {
        return 'assets/MonochromeOptelLogo.svg';
      },
      logoOTP() {
        return process.env.VUE_APP_PLATFORM_LOGO;
      },
      partnerLogo() {
        return process.env.VUE_APP_PARTNER_LOGO;
      },
      rev() {
        return process.env.VUE_APP_GIT_HASH;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .grid {
    margin: 0px;
  }
  .left-side {
    width: 50%;
    padding: 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .reinventing {
      position: absolute;
      top: 45%;
      right: 20%;
      height: 110px;
    }
  }
  .bottom-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45vh;
  }
  .slogan {
    margin-left: 40px;
  }
  .rev-info {
    margin: 20px 40px;
    font-size: 80%;
    color: #fff;
  }
  .right-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
  }
  .top-right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
  }
  .bottom-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 35%;
    right: 0;
    left: 0;
  }
  .greet {
    font-weight: 500;
    color: #003c71;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .full-screen {
    height: 100%;
    width: 100%;
  }
  .logo-box {
    height: 40%;
  }
  .logo {
    height: 56px;
    margin: 10px;
  }
  .left-optel-logo {
    height: 50px;
    filter: invert(100%);
  }
  .left-logo {
    width: 80%;
  }
  .divider {
    height: 35px;
    width: 2px;
    background-color: #fff;
    margin: 5px 20px;
  }
  .login-field {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .login-field-button {
    background-color: #003c71;
    width: 20vw;
    font-size: 120%;
    font-weight: 700;
    padding: 15px 40px;
    margin-top: 20px;
  }
  .login-button {
    width: 300px;
    padding: 15px 40px;
    margin-top: 30px;

    background: white;
    color: #003c71;
    border: 1px solid #003c71;
    border: 2px solid #003c71;
    font-weight: 600;
    font-size: 16px;

    &:hover {
      background: #003c71 !important;
    }
  }

  @media (max-width: 767px) {
    .left-side {
      display: none;
    }

    .right-side {
      width: 100%;
    }
  }
</style>
