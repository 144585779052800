import { getFromRpc } from './api';

export const idToString = (s) => {
    if (s) {
        return s.split('_').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
    }
    else {
        return '';
    }
}

export const fakeSearchLot = (search) => {
    return getFromRpc('fakeSearchLot', [search]);
}

export const fakeSearchMaterials = (search) => {
    return getFromRpc('fakeSearchMaterials', [search]);
}

export const fakeSearchSuppliers = (search) => {
    return getFromRpc('fakeSearchSuppliers', [search]);
}

/**
 * Decodes a unique lot number and extracts the company index, product index and production day
 * @param {string} lot The encoded lot number
 * @returns {object} The company index, product name and indexes and date
 */
export const decodeLot = (lot) => {
    return getFromRpc('decodeLot', [lot]);
};

