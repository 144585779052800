<template>
  <Dialog
    header="Header"
    v-model:visible="isVisible_"
    :style="{ width: '30vw' }"
    :modal="true"
    v-on:hide="hide"
  >
    <template #header>
      <h3>{{$t("Log out")}}</h3>
    </template>

    <div class="flex justify-content-center flex-column">
     <p>{{$t("Are you sure you want to log out?")}}</p>
    </div>

    <template #footer>
      <Button
        :label="$t('No')"
        @click="isVisible_=false"
      /> <Button
        :label="$t('Yes')"
        @click="logOut()"
      />
    </template>
  </Dialog>
</template>

<script>

export default {
  name: "ConfirmDialog",
  data() {
    return {
      isVisible_: false,
      isLoading: false,
    };
  },

  methods: {

    logOut(){
        this.$auth.bus.emit('logout');
        this.hide();
    },
    show() {
      this.isVisible_ = true;
    },
    hide() {
      this.isVisible_ = false;
    }

  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-password) {
  width: 100%;
}

::v-deep(.p-password input) {
  width: 100%;
}

.form-input {
  width: 100%;
}
</style>
